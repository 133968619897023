<template>
    <div class="page-container">
        <van-empty image="error" :description="getErrorMessage()">
            <van-button round type="danger" class="bottom-button" url="/home">
                返回首页
            </van-button>
        </van-empty>

    </div>
</template>

<script>
export default {
  data () {
    return {
      code: 404
    }
  },
  mounted () {
    if (this.$route.params.code) {
      this.code = this.$route.params.code
    }
  },
  methods: {
    getErrorMessage () {
      let message = null
      switch (this.code) {
        case 404:
          message = '找不到该页面'
          break
        case 401:
          message = '身份认证错误'
          break
        default:
          message = '未知错误'
          break
      }
      return message
    }
  }
}
</script>

<style>
</style>
